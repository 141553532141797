import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "animate.css";
// 样式
import "./assets/css/styles.css";
import "./assets/css/element/index.scss";
import "./assets/fonts/fonts.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
import i18n from "./i18n/index"; //国际化
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n, //使用国际化
  render: (h) => h(App),
}).$mount("#app");
