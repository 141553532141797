var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"qudao",staticClass:"sixPage"},[_c('div',{staticClass:"bigTitle bold animate__animated animate__fadeInUp"},[_vm._v(" "+_vm._s(_vm.$t("特别后端支撑服务，全面用卡体验"))+" ")]),_c('div',{staticClass:"main animate__animated animate__fadeInUpBig"},[_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/1.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t("7x24小时在线贵宾服务"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content1"}},[_vm._v(" "+_vm._s(_vm.$t( "全年无休，提供7X24小时的在线服务，周年纪念日、办公聚餐、商务旅行及私人旅行定制等全方位的卡片福利礼遇服务" ))+" ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/2.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t("线上线下的高水准使用"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content2"}},[_vm._v(" "+_vm._s(_vm.$t( "线上线下的全覆盖使用体验，ApplePay、GooglePAY、PayPay等全线上绑卡消费，同步支持线下无卡消费场景" ))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/2.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(" "+_vm._s(_vm.$t("Visa及Master顶级支持"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content4"}},[_vm._v(" "+_vm._s(_vm.$t( "全球两大顶级卡组支持，可在世界大部分地区使用，体验无与伦比的地位" ))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/3.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(" "+_vm._s(_vm.$t("本地及海外顶级酒店预定"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content5"}},[_vm._v(" "+_vm._s(_vm.$t( "无论在本地还是海外，超过千家住宿酒店中，您可享受优先预定、客房升级、免费早餐、提前入住、延迟退房等各种优惠服务" ))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/4.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(" "+_vm._s(_vm.$t("免费机场出行休息厅服务"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t( "免费出行特权，可使用多个国家和地区超过高达600城市的1500多个机场及出行场所" ))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/5.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(" "+_vm._s(_vm.$t("豪华酒店及高端餐饮服务"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t("提过优质的VIP待遇，可享受优先预定高端特殊餐饮的预定服务"))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/3.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(_vm._s(_vm.$t("专属vip特殊通道服务")))]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t( "通过kdcard，可支持预定特殊场所的服务，无需担心，KdCard可以帮助您完成高端会员制会所及非开放式招待场所的特殊预约服务" ))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/4.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(" "+_vm._s(_vm.$t("专享非公众开放的独家福利"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t( "通过卡片，可专享独家福利待遇，我们将提供私密专属高端场所预定、部分限量发行商品的优先购买权、预售及特殊活动邀请等独家福利" ))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 250px'
          : 'height: 220px')},[_c('img',{attrs:{"src":require("@/assets/imgs/5.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(_vm._s(_vm.$t("首年年费减免")))]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t( "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100%享受首年年费减免服务" ))+"! ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }