<template>
  <div class="fivePage" ref="qudao">
    <div class="smallTitle demibold">Smart pay {{ $t("无界卡") }}</div>
    <div class="middle animate__animated animate__rotateIn animate__slow">
      <div class="main">
        <div class="img">
          <img src="@/assets/imgs/yellow.png" alt="" class="yellow" />
          <img src="@/assets/imgs/red.png" alt="" class="red" />
          <div class="flex items-center justify-between date">
            <p class="minTitle demibold">AAKASH DAHAL</p>
            <p class="minTitle demibold">03/25</p>
          </div>
          <div class="cardNumber demibold">
            <span>8888</span>
            <span>8888</span>
            <span>8888</span>
            <span>8888</span>
          </div>
        </div>
      </div>
    </div>
    <ul class="list">
      <li class="flex items-center justify-start animate__faster">
        <img src="@/assets/imgs/bg3-2.png" alt="" />
        <p class="regular">
          {{ $t("专业出行行程规划、机票、餐饮、旅行、全方位的专属服务") }}
        </p>
      </li>
      <li class="flex items-center justify-start animate__faster">
        <img src="@/assets/imgs/bg3-2.png" alt="" />
        <p class="regular">
          {{ $t("高端专属私密社交互动，人脉拓展的便捷通道") }}
        </p>
      </li>
      <li class="flex items-center justify-start animate__faster">
        <img src="@/assets/imgs/bg3-2.png" alt="" />
        <p class="regular">
          {{ $t("私人预定服务，高端会所、食所、专属预约") }}
        </p>
      </li>
      <li class="flex items-center justify-start animate__faster">
        <img src="@/assets/imgs/bg3-2.png" alt="" />
        <p class="regular">
          {{ $t("游艇、私人飞机、豪车预定服务，我们搞定所有，您尽情享受") }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FivePage",
  data() {
    return {
      qudaoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        // console.log(a, b, c, d, 1111);
        if (b + c > a) {
          callback && callback();
        }
        // if (b > 5 * c) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fivePage {
  height: 100%;
  color: #fff;
  background: url("@/assets/imgs/bg1.png") no-repeat;
  padding-bottom: 50px;
  .smallTitle {
    font-size: 20px;
    font-weight: 900;
    line-height: 26.52px;
    text-align: center;
    animation: bounceInUp 1s;
    margin-bottom: 16px;
  }

  .list {
    padding: 0 20px;
    margin-top: 100px;
    color: #fff;
    opacity: 0.8;
    li {
      margin-bottom: 12px;
      &:nth-child(1) {
        animation: zoomInUp 3s;
      }
      &:nth-child(2) {
        animation: zoomInUp 4s;
      }
      &:nth-child(3) {
        animation: zoomInUp 5s;
      }
      &:nth-child(4) {
        animation: zoomInUp 6s;
      }
      img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        line-height: 15.91px;
        text-align: left;
      }
    }
  }
  .middle {
    // height: 100%;
    .main {
      margin-top: 16px;
      padding-top: 45px;
      height: 100%;
      margin-left: 60px;
      .img {
        margin-top: 16px;
        width: 243.79px;
        height: 152.19px;
        transform: rotate(45deg); /* 旋转45度 */
        background: url("@/assets/imgs/bg5-1.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .yellow {
          position: absolute;
          right: 20px;
          top: 20px;
          width: 25.86px;
          height: 25.86px;
          z-index: 111;
        }
        .red {
          width: 25.86px;
          height: 25.86px;
          position: absolute;
          right: 32px;
          top: 20px;
        }
        .date {
          width: 70%;
          position: absolute;
          left: 20px;
          bottom: 40px;
          font-size: 7.39px;
          font-weight: 600;
          line-height: 7.39px;
          text-align: left;
        }
        .cardNumber {
          width: 70%;
          position: absolute;
          left: 20px;
          bottom: 20px;
          font-size: 11.82px;
          line-height: 11.82px;
          letter-spacing: 0.15em;
          text-align: left;
          span {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1366px) {
  .right {
    .main {
      padding-top: 50px !important;
      .img {
        margin-top: 50px !important;
      }
    }
  }
}
</style>
