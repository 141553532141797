<template>
  <div class="threePage" ref="qudao">
    <div class="background"></div>
    <div class="content">
      <div class="smallTitle bold animate__animated animate__bounceInUp">
        {{ $t("随时随地的全球消费") }}
      </div>
      <p class="regular miniTitle">
        {{ $t("从现在开始，享受非凡的优质消费体验") }}
      </p>
      <div class="middle animate__animated animate__fadeInRight">
        <img src="@/assets/imgs/bg3-1.png" alt="" />
      </div>
      <ul class="list">
        <li class="flex items-center justify-start animate__faster">
          <img src="@/assets/imgs/bg3-2.png" alt="" />
          <p class="regular">
            {{
              $t(
                "支持全球范围的无限支持全球商户的无限制消费，任何时间任何地点制消费，走到哪里都不怕"
              )
            }}
          </p>
        </li>
        <li class="flex items-center justify-start animate__faster">
          <img src="@/assets/imgs/bg3-2.png" alt="" />
          <p class="regular">
            {{ $t("数字货币快速转换，真金白银时时到账") }}
          </p>
        </li>
        <li class="flex items-center justify-start animate__faster">
          <img src="@/assets/imgs/bg3-2.png" alt="" />
          <p class="regular">
            {{
              $t("真金白银时时到账是陌生的领域，也是您熟悉的家园，一卡通全球")
            }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThreePage",
  data() {
    return {
      qudaoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b > a + c) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.threePage {
  height: 100%;
  color: #fff;
  padding: 20px 0 0;
  .background {
    position: fixed; /* 固定在视口 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/imgs/bg3.png");
    background-size: cover;
    background-position: center;
    animation: heartBeat 5s infinite; /* 心跳动画 */
    z-index: -1; /* 确保在其他内容后面 */
  }
  .content {
    position: relative; /* 确保内容在背景上方 */
    height: 100%;
    width: 100%;
    .smallTitle {
      font-size: 20px;
      font-weight: 900;
      line-height: 26.52px;
      text-align: center;
      animation: bounceInUp 1s;
    }
    .miniTitle {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.56px;
      text-align: left;
      animation: bounceInUp 2s;
      color: #fff;
      opacity: 0.9;
      text-align: center;
    }

    .middle {
      // height: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 335px;
        height: 240px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    .list {
      margin-top: 31px;
      padding: 0 20px 20px;
      li {
        margin-bottom: 12px;
        color: #fff;
        opacity: 0.8;
        &:nth-child(1) {
          animation: zoomInUp 3s;
        }
        &:nth-child(2) {
          animation: zoomInUp 4s;
        }
        &:nth-child(3) {
          animation: zoomInUp 5s;
        }
        img {
          width: 7.78px;
          height: 6.05px;
          margin-right: 12px;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 15.91px;
          text-align: left;
        }
      }
    }
  }
}
</style>
