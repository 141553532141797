<template>
  <!-- <WrapBox> -->
  <div class="sixPage" ref="qudao">
    <div class="bigTitle bold animate__animated animate__fadeInUp">
      {{ $t("特别后端支撑服务，全面用卡体验") }}
    </div>
    <div class="main animate__animated animate__fadeInUpBig">
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/1.png" alt="" />
        <div class="smallTitle demibold animate__animated animate__fadeInUpBig">
          {{ $t("7x24小时在线贵宾服务") }}
        </div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content1"
        >
          {{
            $t(
              "全年无休，提供7X24小时的在线服务，周年纪念日、办公聚餐、商务旅行及私人旅行定制等全方位的卡片福利礼遇服务"
            )
          }}
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/2.png" alt="" />
        <div class="smallTitle demibold animate__animated animate__fadeInUpBig">
          {{ $t("线上线下的高水准使用") }}
        </div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content2"
        >
          {{
            $t(
              "线上线下的全覆盖使用体验，ApplePay、GooglePAY、PayPay等全线上绑卡消费，同步支持线下无卡消费场景"
            )
          }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/2.png" alt="" />
        <div class="smallTitle demibold">
          {{ $t("Visa及Master顶级支持") }}
        </div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content4"
        >
          {{
            $t(
              "全球两大顶级卡组支持，可在世界大部分地区使用，体验无与伦比的地位"
            )
          }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/3.png" alt="" />
        <div class="smallTitle demibold">
          {{ $t("本地及海外顶级酒店预定") }}
        </div>
        <p
          class="content regular animate__animated animate__fadeInUpBig"
          id="content5"
        >
          {{
            $t(
              "无论在本地还是海外，超过千家住宿酒店中，您可享受优先预定、客房升级、免费早餐、提前入住、延迟退房等各种优惠服务"
            )
          }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/4.png" alt="" />
        <div class="smallTitle demibold">
          {{ $t("免费机场出行休息厅服务") }}
        </div>
        <p class="content regular animate__animated animate__fadeInUpBig">
          {{
            $t(
              "免费出行特权，可使用多个国家和地区超过高达600城市的1500多个机场及出行场所"
            )
          }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/5.png" alt="" />
        <div class="smallTitle demibold">
          {{ $t("豪华酒店及高端餐饮服务") }}
        </div>
        <p class="content regular animate__animated animate__fadeInUpBig">
          {{ $t("提过优质的VIP待遇，可享受优先预定高端特殊餐饮的预定服务") }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/3.png" alt="" />
        <div class="smallTitle demibold">{{ $t("专属vip特殊通道服务") }}</div>
        <p class="content regular animate__animated animate__fadeInUpBig">
          {{
            $t(
              "通过kdcard，可支持预定特殊场所的服务，无需担心，KdCard可以帮助您完成高端会员制会所及非开放式招待场所的特殊预约服务"
            )
          }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/4.png" alt="" />
        <div class="smallTitle demibold">
          {{ $t("专享非公众开放的独家福利") }}
        </div>
        <p class="content regular animate__animated animate__fadeInUpBig">
          {{
            $t(
              "通过卡片，可专享独家福利待遇，我们将提供私密专属高端场所预定、部分限量发行商品的优先购买权、预售及特殊活动邀请等独家福利"
            )
          }}!
        </p>
      </li>
      <li
        :style="
          currentLanguage == 'zh'
            ? 'height: 180px'
            : currentLanguage == 'en'
            ? 'height: 250px'
            : 'height: 220px'
        "
      >
        <img src="@/assets/imgs/5.png" alt="" />
        <div class="smallTitle demibold">{{ $t("首年年费减免") }}</div>
        <p class="content regular animate__animated animate__fadeInUpBig">
          {{
            $t(
              "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100%享受首年年费减免服务"
            )
          }}!
        </p>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "SixPage",
  data() {
    return {
      qudaoShow: false,
      currentLanguage: "zh",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.currentLanguage = localStorage.getItem("accept_language");
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var b, c, a, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        // e = dom.offsetHeight; // 元素高度
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b + c > a + e) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
    // getFont(text, container) {
    //   const letters = text.split("");
    //   letters.forEach((letter, index) => {
    //     const span = document.createElement("span");
    //     span.classList.add("reveal");
    //     span.textContent = letter;

    //     setTimeout(() => {
    //       container.appendChild(span);
    //       span.classList.add(
    //         "animate__animated",
    //         "animate__fadeInUp",
    //         "animate__faster"
    //       );
    //     }, 100 * index);
    //   });
    // },
    // removeFont(container) {
    //   const children = container.children; // 获取子元素集合
    //   // 转换为数组并反向遍历以避免索引问题
    //   Array.from(children).forEach((child) => child.remove());
    // },
  },
};
</script>

<style lang="scss" scoped>
.sixPage {
  height: 100%;
  background: #131313;
  color: #fff;
  padding: 50px 0;
  .bigTitle {
    font-size: 20px;
    font-weight: 900;
    line-height: 26.52px;
    text-align: center;
  }
  .main {
    margin-top: 32px;
    overflow-x: scroll;
    padding-left: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    li {
      list-style: none;
      position: relative;
      overflow: hidden; /* 或者 auto */
      // width: 380px;
      // height: 302px;
      width: 150px;
      // min-height: 220px;
      border-radius: 12px;
      border: 3px solid;
      display: inline-block;
      box-sizing: border-box;
      border-image-source: radial-gradient(
        80.79% 50% at 50% 50%,
        #8c4424 0%,
        rgba(140, 68, 36, 0) 100%
      );
      backdrop-filter: blur(38.62782287597656px);
      padding: 16px 12px 20px;
      margin-right: 12px;
      padding-bottom: 20px;
      &:hover {
        transform: scale(1.02); /* 鼠标滑过时放大 */
      }
      img {
        width: 36px;
        height: 36px;
        opacity: 1;
      }
      .smallTitle {
        font-size: 12px;
        line-height: 15.91px;
        text-align: left;
        margin-top: 16px;
        margin-bottom: 11px;
        color: #fff;
        opacity: 1;
      }
      .content {
        font-size: 10px;
        font-weight: 400;
        line-height: 13.26px;
        text-align: left;
        width: 126px;
        height: 65px;
        color: #ffffff;
        opacity: 0.8;
        white-space: wrap;
      }
    }
    li::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("@/assets/imgs/bg2-1.png") no-repeat;
      opacity: 0.3;
      background-size: 100% 100%;
      z-index: -1; /* 确保伪元素在内容之下 */
    }
  }
}

// @media (min-width: 1366px) {
//   .main {
//     li {
//       padding: 40px 30px !important;
//       margin-bottom: 10px;
//     }
//   }
// }
</style>
