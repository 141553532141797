<template>
  <div class="fourPage" ref="qudao">
    <div class="background"></div>
    <div class="bigTitle bold">{{ $t("跨境线上消费模式") }}</div>
    <div class="middle animate__animated animate__fadeInLeft">
      <img src="@/assets/imgs/bg4.png" alt="" />
    </div>
    <p class="regular miniTitle" id="miniTitle">
      <span class="reveal" style="display: none" ref="textElement">
        {{
          $t(
            "世界有多大，心就有多大，支持绑定多电子支付场景，GooglePlay、AppStore、Steam、 Amazon. 全宇宙都是你的消费乐园!"
          )
        }}</span
      >
    </p>

    <ul class="list">
      <li>
        <img src="@/assets/imgs/bg4-1.png" alt="" />
      </li>
      <li>
        <img src="@/assets/imgs/bg4-2.png" alt="" />
      </li>
      <li>
        <img src="@/assets/imgs/bg4-3.png" alt="" />
      </li>
      <li>
        <img src="@/assets/imgs/bg4-4.png" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FourPage",
  data() {
    return {
      qudaoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const text = this.$refs.textElement.innerText;
    const letters = text.split("");
    const container = document.getElementById("miniTitle");
    letters.forEach((letter, index) => {
      const span = document.createElement("span");
      span.classList.add("reveal");
      span.textContent = letter;

      setTimeout(() => {
        container.appendChild(span);
        span.classList.add(
          "animate__animated",
          "animate__bounceInRight",
          "animate__faster"
        );
      }, 100 * index);
    });
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b > a + c / 2) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fourPage {
  height: 100%;
  color: #fff;
  padding-bottom: 50px;
  .background {
    position: fixed; /* 固定在视口 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/imgs/bg3.png");
    background-size: cover;
    background-position: center;
    animation: heartBeat 5s infinite; /* 心跳动画 */
    z-index: -1; /* 确保在其他内容后面 */
  }
  .bigTitle {
    font-size: 20px;
    font-weight: 900;
    line-height: 26.52px;
    text-align: center;
  }
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
    img {
      width: 335px;
      height: 240px;
      border-radius: 8px;
    }
  }

  .miniTitle {
    padding: 0 23px;
    margin-top: 30px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.91px;
    text-align: center;
    animation: bounceInRight 2s;
    color: #fff;
    opacity: 0.9;
  }
  .list {
    margin-top: 20px;
    padding: 0 20px;
    li {
      width: 72px;
      height: 42px;
      display: inline-block;
      margin-right: 13px;
      // margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        transform: scale(1.05); /* 鼠标滑过时放大 */
      }
    }
  }
}
// @keyframes heartbeat {
//   0%,
//   100% {
//     transform: scale(1);
//   }
//   25% {
//     transform: scale(1.05); /* 放大效果 */
//   }
//   50% {
//     transform: scale(1); /* 恢复原始大小 */
//   }
//   75% {
//     transform: scale(1.05); /* 再次放大 */
//   }
// }
</style>
