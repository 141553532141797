<template>
  <div class="onePage" ref="qudao">
    <div class="main">
      <div class="bigTitle bold">
        {{ $t("USDT区块链") }}
      </div>
      <div class="bigTitle bold hot">X Master &Visa</div>
      <div class="topContent regular">
        <p id="textElement">
          <span class="reveal" style="display: none" ref="textElement">{{
            $t("数字货币与传统金融的跨界交融")
          }}</span>
        </p>
        <p id="textElement1">
          <span class="reveal" style="display: none" ref="textElement1">{{
            $t("创新性金融整合应用体，财富跨界安全自由")
          }}</span>
        </p>
      </div>
      <div class="download demibold" @click="download">
        {{ $t("立即下载") }}
      </div>
      <div class="flex flex-col items-start justify-start bottom">
        <div class="imgBox1 animate__animated animate__fadeInTopRight">
          <img src="@/assets/imgs/card1.png" alt="" class="img1" />
        </div>
        <div class="imgBox2 animate__animated animate__fadeInTopRight">
          <img src="@/assets/imgs/card2.png" alt="" class="img1" />
        </div>
        <div class="imgBox3 animate__animated animate__fadeInTopRight">
          <img src="@/assets/imgs/card3.png" alt="" class="img1" />
        </div>
      </div>
    </div>
    <img
      src="@/assets/imgs/left.png"
      alt=""
      class="animate__animated animate__rotateIn leftImg"
    />
    <img
      src="@/assets/imgs/right.png"
      alt=""
      class="animate__animated animate__rotateIn rightImg"
    />
  </div>
</template>

<script>
// import Header from "./one/header.vue";
import gsap from "gsap";
// import { SplitText } from "split-text";
export default {
  name: "OnePage",
  data() {
    return {
      qudaoShow: true,
      text: "Master &Visa",
      textArray: [],
      currentLanguage: "zh",
    };
  },
  mounted() {
    this.textArray = this.text.split(""); // 将文本分割为字符数组
    this.animateText(); // 调用动画函数
    const text = this.$refs.textElement.innerText;
    const letters = text.split("");
    const container = document.getElementById("textElement");
    letters.forEach((letter, index) => {
      const span = document.createElement("span");
      span.classList.add("reveal");
      span.textContent = letter;

      setTimeout(() => {
        container.appendChild(span);
        span.classList.add(
          "animate__animated",
          "animate__fadeInUp",
          "animate__faster"
        );
        if (index == letters.length - 1) {
          const text1 = this.$refs.textElement1.innerText;
          const letters1 = text1.split("");
          const container1 = document.getElementById("textElement1");
          letters1.forEach((letter, index) => {
            const span = document.createElement("span");
            span.classList.add("reveal");
            span.textContent = letter;

            setTimeout(() => {
              container1.appendChild(span);
              span.classList.add(
                "animate__animated",
                "animate__fadeInUp",
                "animate__faster"
              );
            }, 100 * index);
          });
        }
      }, 100 * index);
    });
    this.currentLanguage = localStorage.getItem("accept_language");
    // window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 10, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (c <= a) {
          callback && callback();
        }
        if (b > c) {
          this.qudaoShow = false;
        } else {
          this.qudaoShow = true;
        }
      }
    },
    animateText() {
      const chars = this.$refs.chars;
      gsap.from(chars, {
        duration: 0.5,
        opacity: 0,
        y: 10,
        stagger: 0.1, // 每个字符之间的延迟
        ease: "power1.out",
      });
    },
    download() {
      window.location.href =
        "https://dktbxt0rwhe3u.cloudfront.net/apk/smartpay_official.apk";
    },
  },
};
</script>

<style lang="scss" scoped>
.onePage {
  // min-height: 480px;
  height: 100%;
  background: url("@/assets/imgs/bg1.png") no-repeat;
  font-size: 20px;
  color: #fff;
  position: relative;
  overflow: hidden;
  .leftImg {
    position: absolute;
    width: 124px;
    height: 164.51px;
    bottom: -82px;
    left: -30px;
    opacity: 0.6px;
    animation: rotate 20s linear infinite;
    z-index: 0;
  }
  .rightImg {
    position: absolute;
    width: 113.73px;
    height: 150.89px;
    bottom: 10%;
    right: -57px;
    opacity: 0.6px;
    animation: rotate 20s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .main {
    min-height: 480px;
    padding: 94px 55px 30px;
    .bigTitle {
      text-align: center;
      font-size: 32px;
      animation: fadeInUp 1s linear forwards;
    }
    .topContent {
      width: 266px;
      // height: 38px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.56px;
      text-align: center;
      margin-top: 20px;
    }
    .hot {
      background-image: linear-gradient(to right, #846c37, #5f4b1f 50%);
      // // background: linear-gradient(
      // //   135deg,
      // //   #846c37,
      // //   #5f4b1f,
      // //   // rgb(255, 0, 225),
      // //   // rgb(34, 0, 255),
      // //   // red
      // // );
      // // green;
      color: transparent;
      -webkit-background-clip: text;
      background-size: 200% 100%;
      animation: flowlight 5s forwards;
      // animation: fadeInUp 2s linear forwards;
      // animation: colorChange 3s forwards;
    }
    .download {
      z-index: 9;
      background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
      margin: 38px auto 0;
      min-width: 136px;
      height: 38px;
      border-radius: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 21.22px;
      text-align: left;
      cursor: pointer;
      &:hover {
        background: linear-gradient(180deg, #e2a63e 0%, #966728 100%);
        border: none;
        color: #fff;
      }
    }

    .bottom {
      position: relative;
      width: 320px;
      height: 242px;
      .imgBox1,
      .imgBox2,
      .imgBox3 {
        img {
          width: 287.39px;
          height: 121.22px;
          border-image-source: linear-gradient(
            120.32deg,
            rgba(255, 255, 255, 0.3) -1.91%,
            rgba(255, 255, 255, 0) 61.93%
          );
          border-radius: 32.38px 0px 0px 0px;
          border: 1.24px solid;
        }
      }
      .imgBox1 {
        position: absolute;
        bottom: 35%;
        right: 10px;
        animation: fadeInTopRight 3s alternate;
        z-index: 111;
      }
      .imgBox2 {
        position: absolute;
        bottom: 15%;
        right: 20px;
        z-index: 110;
        animation: fadeInTopRight 2s alternate;
      }
      .imgBox3 {
        position: absolute;
        bottom: 0;
        right: 30px;
        z-index: 100;
        animation: fadeInTopRight 1s alternate;
      }
    }

    @keyframes flowlight {
      0% {
        filter: hue-rotate(0deg);
      }
      100% {
        color: #fff;
      }
    }
  }
  @keyframes slideIn {
    to {
      opacity: 1; /* 最终透明度 */
      transform: translateX(0); /* 到达原位 */
    }
  }
  @keyframes colorChange {
    0% {
      color: #846c37;
    }
    50% {
      color: #5f4b1f;
    }
    80% {
      color: #846c37;
    }
    100% {
      color: #fff;
    }
  }
}
</style>
