var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"qudao",staticClass:"twoPage"},[_c('div',{staticClass:"bigTitle demibold animate__animated animate__fadeInUp"},[_vm._v(" "+_vm._s(_vm.$t("核心功能"))+" ")]),_c('div',{staticClass:"main animate__animated animate__fadeInUpBig"},[_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 300px'
          : 'height: 250px')},[_c('img',{attrs:{"src":require("@/assets/imgs/1.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t("专属服务"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content1"}},[_vm._v(" "+_vm._s(_vm.$t("卡片专属福利服务，出行、商务、旅游、住宿，精彩横生包罗万象"))+" ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 300px'
          : 'height: 250px')},[_c('img',{attrs:{"src":require("@/assets/imgs/2.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold animate__animated animate__fadeInUpBig"},[_vm._v(" "+_vm._s(_vm.$t("全球消费"))+" ")]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content2"}},[_vm._v(" "+_vm._s(_vm.$t("撒欢自在，从现在开始，做你想做的事"))+"! ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 300px'
          : 'height: 250px')},[_c('img',{attrs:{"src":require("@/assets/imgs/6.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(_vm._s(_vm.$t("全球提现")))]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content3"}},[_vm._v(" "+_vm._s(_vm.$t("全球275个地区与国家的实体卡自由提现服务。从容面对，安全体验"))+" ")])]),_c('li',{style:(_vm.currentLanguage == 'zh'
          ? 'height: 180px'
          : _vm.currentLanguage == 'en'
          ? 'height: 300px'
          : 'height: 250px')},[_c('img',{attrs:{"src":require("@/assets/imgs/3.png"),"alt":""}}),_c('div',{staticClass:"smallTitle demibold"},[_vm._v(_vm._s(_vm.$t("账户管理")))]),_c('p',{staticClass:"content regular animate__animated animate__fadeInUpBig",attrs:{"id":"content4"}},[_vm._v(" "+_vm._s(_vm.$t("开卡、充值、冻结、注销操作尽在掌控，体验拉满，事事安心"))+"! ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }