const zh = {
  登录: "登录",
  注册: "注册",
  申请卡片: "申请卡片",
  USDT区块链: "USDT区块链",
  数字货币与传统金融的跨界交融: "数字货币与传统金融的跨界交融",
  "创新性金融整合应用体，财富跨界安全自由":
    "创新性金融整合应用体，财富跨界安全自由",
  核心功能: "核心功能",
  全球提现: "全球提现",
  "全球275个地区与国家的实体卡自由提现服务。从容面对，安全体验":
    "全球275个地区与国家的实体卡自由提现服务。从容面对，安全体验",
  全球消费: "全球消费",
  "撒欢自在，从现在开始，做你想做的事": "撒欢自在，从现在开始，做你想做的事",
  账户管理: "账户管理",
  "开卡、充值、冻结、注销操作尽在掌控，体验拉满，事事安心":
    "开卡、充值、冻结、注销操作尽在掌控，体验拉满，事事安心",
  虚实互动: "虚实互动",
  "虚拟VCC快速开卡，时时掌控，快速消费数字USDT极速转换，刻刻转换，跨界便捷":
    "虚拟VCC快速开卡，时时掌控，快速消费数字USDT极速转换，刻刻转换，跨界便捷",
  支持: "支持",
  "真实感受区块链货币与现实货币的无限可能!是USDT也是您手握的现金":
    "真实感受区块链货币与现实货币的无限可能!是USDT也是您手握的现金",
  随时随地的全球消费: "随时随地的全球消费",
  "从现在开始，享受非凡的优质消费体验": "从现在开始，享受非凡的优质消费体验",
  "支持全球范围的无限支持全球商户的无限制消费，任何时间任何地点制消费，走到哪里都不怕":
    "支持全球范围的无限支持全球商户的无限制消费，任何时间任何地点制消费，走到哪里都不怕",
  "数字货币快速转换，真金白银时时到账": "数字货币快速转换，真金白银时时到账",
  "真金白银时时到账是陌生的领域，也是您熟悉的家园，一卡通全球":
    "真金白银时时到账是陌生的领域，也是您熟悉的家园，一卡通全球",
  无界卡: "无界卡",
  "24小时贴身顾问服务，专属秘书服务": "24小时贴身顾问服务，专属秘书服务",
  "专业出行行程规划、机票、餐饮、旅行、全方位的专属服务":
    "专业出行行程规划、机票、餐饮、旅行、全方位的专属服务",
  "高端专属私密社交互动，人脉拓展的便捷通道":
    "高端专属私密社交互动，人脉拓展的便捷通道",
  "私人预定服务，高端会所、食所、专属预约":
    "私人预定服务，高端会所、食所、专属预约",
  "游艇、私人飞机、豪车预定服务，我们搞定所有，您仅需享受":
    "游艇、私人飞机、豪车预定服务，我们搞定所有，您尽情享受",
  "特别后端支撑服务，全面用卡体验": "特别后端支撑服务，全面用卡体验",
  "7x24小时在线贵宾服务": "7x24小时在线贵宾服务",
  "全年无休，提供7X24小时的在线服务，周年纪念日、办公聚餐、商务旅行及私人旅行定制等全方位的卡片福利礼遇服务":
    "全年无休，提供7X24小时的在线服务，周年纪念日、办公聚餐、商务旅行及私人旅行定制等全方位的卡片福利礼遇服务",
  线上线下的高水准使用: "线上线下的高水准使用",
  "线上线下的全覆盖使用体验，ApplePay、GooglePAY、PayPay等全线上绑卡消费，同步支持线下无卡消费场景":
    "线上线下的全覆盖使用体验，ApplePay、GooglePAY、PayPay等全线上绑卡消费，同步支持线下无卡消费场景",
  Visa及Master顶级支持: "Visa及Master顶级支持",
  "全球两大顶级卡组支持，可在世界大部分地区使用，体验无与伦比的地位":
    "全球两大顶级卡组支持，可在世界大部分地区使用，体验无与伦比的地位",
  本地及海外顶级酒店预定: "本地及海外顶级酒店预定",
  "无论在本地还是海外，超过千家住宿酒店中，您可享受优先预定、客房升级、免费早餐、提前入住、延迟退房等各种优惠服务":
    "无论在本地还是海外，超过千家住宿酒店中，您可享受优先预定、客房升级、免费早餐、提前入住、延迟退房等各种优惠服务",
  免费机场出行休息厅服务: "免费机场出行休息厅服务",
  "免费出行特权，可使用多个国家和地区超过高达600城市的1500多个机场及出行场所":
    "免费出行特权，可使用多个国家和地区超过高达600城市的1500多个机场及出行场所",
  豪华酒店及高端餐饮服务: "豪华酒店及高端餐饮服务",
  "提过优质的VIP待遇，可享受优先预定高端特殊餐饮的预定服务":
    "提过优质的VIP待遇，可享受优先预定高端特殊餐饮的预定服务",
  专属vip特殊通道服务: "专属vip特殊通道服务",
  "通过kdcard，可支持预定特殊场所的服务，无需担心，KdCard可以帮助您完成高端会员制会所及非开放式招待场所的特殊预约服务":
    "通过kdcard，可支持预定特殊场所的服务，无需担心，KdCard可以帮助您完成高端会员制会所及非开放式招待场所的特殊预约服务",
  专享非公众开放的独家福利: "专享非公众开放的独家福利",
  "通过卡片，可专享独家福利待遇，我们将提供私密专属高端场所预定、部分限量发行商品的优先购买权、预售及特殊活动邀请等独家福利":
    "通过卡片，可专享独家福利待遇，我们将提供私密专属高端场所预定、部分限量发行商品的优先购买权、预售及特殊活动邀请等独家福利",
  首年年费减免: "首年年费减免",
  "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100":
    "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100",
  专属服务: "专属服务",
  "卡片专属福利服务，出行、商务、旅游、住宿，精彩横生包罗万象":
    "卡片专属福利服务，出行、商务、旅游、住宿，精彩横生包罗万象",
  年费: "年费",
  "金卡：350 美金": "金卡：350 美金",
  "白金卡：760美金": "白金卡：760美金",
  "黑金卡：1500美金": "黑金卡：1500美金",
  开年卡首年达标免年费: "开年卡首年达标免年费",
  附加卡: "附加卡",
  家庭卡免费x2: "家庭卡免费x2",
  公司卡免费x2: "公司卡免费x2",
  转U费用: "转U费用",
  "1+1美金/笔": "1+1美金/笔",
  充值费用: "充值费用",
  "2%/笔": "2%/笔",
  支持品牌: "支持品牌",
  发卡时限: "发卡时限",
  VCC申请后2小时内: "VCC申请后2小时内",
  年龄限制: "年龄限制",
  "20岁以上群体": "20岁以上群体",
  特定增值: "特定增值",
  服务: "服务",
  根据卡类享受不同的特定福利: "根据卡类享受不同的特定福利",
  申请步骤介绍: "申请步骤介绍",
  "您仅需享受最快10分钟申请 立即拥有输你的自己的SmartCard":
    "您仅需享受最快10分钟申请 立即拥有输你的自己的SmartCard",
  "申请前务必核对申请条件及基础信息!": "申请前务必核对申请条件及基础信息!",
  "在线会员申请，注册1分钟": "在线会员申请，注册1分钟",
  "完成会员申请后开，开卡填写资料最长5分钟":
    "完成会员申请后开，开卡填写资料最长5分钟",
  "将USDT转入钱包，增加自己的数字资产": "将USDT转入钱包，增加自己的数字资产",
  "（请根据需求，量力而行）": "（请根据需求，量力而行）",
  "申请开卡，选择您希望的品牌卡片（visa":
    "申请开卡，选择您希望的品牌卡片（visa",
  "或master），将USDT转入卡内转变为消": "或master），将USDT转入卡内转变为消",
  "费额度（此操作可重复进行，可逆向操": "费额度（此操作可重复进行，可逆向操",
  "作，U转卡2%-3%费用，卡转回U免费）": "作，U转卡2%-3%费用，卡转回U免费）",
  立即开卡: "立即开卡",
  语言: "语言",
  支付产品: "支付产品",
  线下支付: "线下支付",
  线上支付: "线上支付",
  关于我们: "关于我们",
  USDT支持: "USDT支持",
  收费标准: "收费标准",
  网上支付: "网上支付",
  联系我们: "联系我们",
  常见问题: "常见问题",
  网站使用条款: "网站使用条款",
  Cookie声明: "Cookie声明",
  立即下载: "立即下载",
  跨境线上消费模式: "跨境线上消费模式",
  "世界有多大，心就有多大，支持绑定多电子支付场景，GooglePlay、AppStore、Steam、 Amazon. 全宇宙都是你的消费乐园!":
    "世界有多大，心就有多大，支持绑定多电子支付场景，GooglePlay、AppStore、Steam、 Amazon. 全宇宙都是你的消费乐园!",
  "游艇、私人飞机、豪车预定服务，我们搞定所有，您尽情享受":
    "游艇、私人飞机、豪车预定服务，我们搞定所有，您尽情享受",
  "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100%享受首年年费减免服务":
    "申卡首年年费减免服务，通过超优惠政策，现在申卡，可100%享受首年年费减免服务",
  费率及费用介绍: "费率及费用介绍",
};
export default zh;
